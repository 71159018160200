import Vue from 'vue'
import Router from 'vue-router'
import Upload from '@views/Upload'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/upload',
			alias: '/',
			name: 'upload',
			component: Upload
		},
		{
			path: '/file/download',
			alias: '/download',
			name: 'download',
			component: () => import(/* webpackChunkName: "download" */ './views/Download.vue')
		},
		{
			path: '/file/delete',
			alias: '/delete',
			name: 'delete',
			component: () => import(/* webpackChunkName: "delete" */ './views/Delete.vue')
		},
		{
			path: '/admin',
			name: 'admin',
			component: () => import(/* webpackChunkName: "admin" */ './views/Admin.vue')
		},
		
		{
			path: '/login',
			name: 'login',
			component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
		},
		{
			path: '/*',
			name: "404",
			component: () => import(/* webpackChunkName: "404" */ './views/404.vue')

		}
	]
})
