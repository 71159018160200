<template>
    <form id="uploadForm" class="form" novalidate="true">
        <!-- SenderName -->
        <div class="field">
            <span class="validation">
                <div v-show="errors.has(inputIds.senderName)" class="tooltip invalid">
                    <i class="fas fa-times-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                </div>
                <div v-show="!errors.has(inputIds.senderName) && fields[inputIds.senderName] && fields[inputIds.senderName].dirty" class="tooltip valid">
                    <i class="fas fa-check-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                </div>
            </span>
            <label class="label" :for="inputIds.senderName"> {{ localization.label.senderName }} </label>
            <input class="input" :id="inputIds.senderName" v-model="form.senderName" v-validate="'required|min:3'" :name="inputIds.senderName" />
        </div>
        <!-- SenderEmail -->
        <div class="field">
            <span class="validation">
                <div v-show="errors.has(inputIds.senderEmail)" class="tooltip invalid">
                    <i class="fas fa-times-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                </div>
                <div v-show="!errors.has(inputIds.senderEmail) && fields[inputIds.senderEmail] && fields[inputIds.senderEmail].dirty" class="tooltip valid">
                    <i class="fas fa-check-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                </div>
            </span>
            <label class="label" :for="inputIds.senderEmail"> {{ localization.label.senderEmail }} </label>
            <input class="input" ref="userEmail" :id="inputIds.senderEmail" v-model="form.senderEmail" :name="inputIds.senderEmail" v-validate="'required|email|zatEmail:otherEmails'" />
        </div>
        <!-- OtherEmails -->
        <div class="field">
            <span class="validation">
                <div v-show="errors.has(inputIds.otherEmails)" class="tooltip invalid">
                    <i class="fas fa-times-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                </div>
                <div v-show="!errors.has(inputIds.otherEmails) && fields[inputIds.otherEmails] && fields[inputIds.otherEmails].dirty" class="tooltip valid">
                    <i class="fas fa-check-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                </div>
            </span>
            <label class="label" :for="inputIds.otherEmails"> {{ localization.label.otherEmails }} </label>
            <input class="input" ref="otherEmails" :id="inputIds.otherEmails" v-model="form.otherEmails" :name="inputIds.otherEmails" v-validate="'required|multipleEmails|zatEmail:userEmail'" />
        </div>

        <!-- AddPassword -->
        <div class="field">
            <input type="checkbox" id="add-password" class="input-out" @change="hasPassword = !hasPassword" />
            <label for="add-password" class="checkbox label" :class="{ checked: hasPassword }">{{ localization.label.addPassword }}</label>
        </div>
        <!-- Password -->
        <div class="field" :class="{ 'field-disabled': !hasPassword }">
            <span v-show="hasPassword" class="validation">
                <div v-show="errors.has(inputIds.password)" class="tooltip invalid">
                    <i class="fas fa-times-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                </div>
                <div v-show="!errors.has(inputIds.password) && fields[inputIds.password] && fields[inputIds.password].dirty" class="tooltip valid">
                    <i class="fas fa-check-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                </div>
            </span>
            <label class="label" :for="inputIds.password"> {{ localization.label.password }} </label>
            <span class="input-group-text" @click="showPassword = !showPassword">
                <i class="fa" :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" aria-hidden="true"></i>
            </span>
            <div class="field has-addons" style="padding-left: 0px;">
                <input :type="showPassword ? 'text' : 'password'" class="input" :id="inputIds.password" v-model="form.password" v-validate="{ required: hasPassword}" :name="inputIds.password" :disabled="!hasPassword" />
            </div>
        </div>
        <!-- AddMessage -->
        <div class="field">
            <input type="checkbox" id="add-message" class="input-out" @change="hasMessage = !hasMessage" />
            <label for="add-message" class="checkbox label" :class="{ checked: hasMessage }">{{ localization.label.addMessage }}</label>
        </div>
        <!-- Message -->
        <div class="field" :class="{ 'field-disabled': !hasMessage }">
            <span v-show="hasMessage" class="validation">
                <div v-show="errors.has(inputIds.message)" class="tooltip invalid">
                    <i class="fas fa-times-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                </div>
                <div v-show="!errors.has(inputIds.message) && fields[inputIds.message] && fields[inputIds.message].dirty" class="tooltip valid">
                    <i class="fas fa-check-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                </div>
            </span>
            <label class="label" :for="inputIds.message"> {{ localization.label.message }} </label>
            <textarea class="input" :id="inputIds.message" v-model="form.message" v-validate="{ required: hasMessage, min: 3, max: 150 }" :name="inputIds.message" :disabled="!hasMessage"></textarea>
        </div>
        <!-- File -->
        <div class="field">
            <input class="input-out" ref="file" type="file" v-validate="'required'" :id="inputIds.file" @change="onChange" :name="inputIds.file" data-vv-validate-on="changes">
            <div class="drop-zone" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <span class="validation">
                    <div v-show="errors.has(inputIds.file)" class="tooltip invalid">
                        <i class="fas fa-times-circle"></i>
                        <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                    </div>
                    <div v-show="!errors.has(inputIds.file) && fields[inputIds.file] && fields[inputIds.file].dirty" class="tooltip valid">
                        <i class="fas fa-check-circle"></i>
                        <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                    </div>
                </span>
                <label class="drop-zone-label label-centered" :for="inputIds.file">
                    <span class="_drop-name label-big" v-show="form.uploadFile">{{ fileName }}</span>
                    <span class="_drop-placeholder" v-show="!form.uploadFile">
                        <span class="_drop-selector label-big label-underline">{{ localization.label.file }} </span>
                        <span>{{ localization.label.dropFile }} </span>
                    </span>
                </label>
            </div>
        </div>
    </form>
</template>

<script>
import Localize from '@localize';
import Common from '@services/Common';

import { EventBus, Names } from '@/EventBus';
import Api from '@/services/Api';

export default {
    data() {
        return {
            inputIds: {
                senderEmail: 'sender-email',
                senderName: 'sender-name',
                otherEmails: 'other-emails',
                password: 'password',
                passwordAgain: 'password-again',
                message: 'message',
                file: 'file'
            },
            form: {
                senderEmail: '',
                senderName: '',
                otherEmails: '',
                message: '',
                password: '',
                uploadFile: ''
            },
            fileName: '',
            passwordAgainCheck: '',
            hasMessage: false,
            hasPassword: false,
            showPassword: false,
            filelist: [], // Store our uploaded files
            userNameFromEmail: '' 
        }
    },
    computed: {
        localization() {
            return Localize.uploadForm()
        },
        formValid() {
            return Object.keys(this.fields).every(field => {
                return this.fields[field] && this.fields[field].valid;
            });
        }
    },
    created() {
        this.fetchUserInfo()
    },
    methods: {
        async fetchUserInfo() {
            const userInfo = await Api.getUserInfo();
            
            if (userInfo) {
                this.form.senderEmail = userInfo.Email;
                const [firstName, lastName] = userInfo.Email.split('@')[0].split('.');
                const capFirstName = firstName.charAt(0).ToUpperCase() + firstName.slice(1);
                const capLastName = lastName.charAt(0).ToUpperCase() + lastName.slice(1);
                this.form.senderName = `${capFirstName} ${capLastName}`;
                this.userNameFromEmail = `${capFirstName} ${capLastName}`;
            }
        },

        isFormValid() {
            this.$validator.validateAll();
            return this.formValid;
        },
        createFormData() {
            if (!this.formValid) throw new Error('form is not valid');

            var formData = new FormData();

            formData.append('fileRequestId', Common.generateRequestId(this.form.uploadFile));
            formData.append('userName', this.form.senderName);
            formData.append('userEmail', this.form.senderEmail);
            formData.append('sendEmails', this.form.otherEmails);
            formData.append('password', this.form.password);
            formData.append('message', this.form.message);
            formData.append('fileName', this.form.uploadFile.name ? this.form.uploadFile.name : '');
            formData.append('size', this.form.uploadFile.size ? this.form.uploadFile.size : 0);

            return formData;
        },
        getFile() {
            return this.form.uploadFile;
        },
        onChange() {
            this.filelist = [...this.$refs.file.files];
            console.log(this.filelist);
            if (!this.filelist.length) {
                this.form.uploadFile = ''
                this.fileName = ''
                return
            }

            this.form.uploadFile = this.filelist[0]
            this.fileName = this.form.uploadFile.name
            this.$validator.validate('file');

            EventBus.$emit(Names.FileSelected);
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            event.currentTarget.style.backgroundColor = '#B2D6C2';
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.style.backgroundColor = 'white';
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            event.currentTarget.dispatchEvent(new Event("change"));
            // Clean up
            event.currentTarget.style.backgroundColor = 'white';
        },
        reset () {
            this.form.senderEmail = ''
            this.form.senderName = ''
            this.form.otherEmails = ''
            this.form.message = ''
            this.form.password = ''
            this.form.uploadFile = ''
            this.form.fileName = ''
            this.passwordAgainCheck = ''
            this.hasMessage = false
            this.hasPassword = false
            
            this.$nextTick(() => {            
                this.errors.clear()
                Object.keys(this.inputIds).forEach((id) => {
                    this.$validator.flag(id, {
                        dirty: false,
                        pristine: true
                    })
                })
            })
            window.location.reload();
        }
    }
}
</script>
<style>
  @import '../styles/progress.css';
</style>

<style lang="sass">
._drop
    &-name:hover
        color: $color-primary
    &-placeholder:hover ._drop-selector
        color: $color-primary
        cursor: pointer
</style>
